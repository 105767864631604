import { ToastContainerProps } from "react-toastify";
import { TeamTypeEntity } from "./enum";

export const searchInputPlaceholder =
  "Type min. 3 letters and press enter to search";

export const maxLength10 = 10;
export const maxLength30 = 30;
export const maxLength150 = 150;
export const maxLength100 = 100;
export const maxLength800 = 800;
export const maxLength10000 = 10000;
export const maxLength100000 = 100000;

export const backSpaceKeyCode = 8;
export const tabSpacekeyCode = 9;
export const deleteKeyCode = 46;
export const enterKeyCode = 13;
export const arrowLeftKeyCode = 37;
export const arrowUpKeyCode = 38;
export const arrowRightKeyCode = 39;
export const arrowDownKeyCode = 40;
export const copyKeyCode = 67;
export const pasteKeyCode = 86;
export const cutKeyCode = 88;
export const selectAllKeyCode = 65; 
export const ctrlKeyCode = 17; 
export const undoKeyCode = 90;

export const imageFileExtension = ["jpeg", "png", "jpg"];

export const fileSize = "5,242,880";

export const toastOptObj: ToastContainerProps = {
  position: "top-center",
  autoClose: 3500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  theme: "colored",
};

export const rowsPerPageOptions = [10, 25, 50, 75];

export const statusLists: { name: string; code: boolean }[] = [
  { name: "Active", code: true },
  { name: "Inactive", code: false },
];

export const teamLists: { name: string; code: string }[] = [
  { name: "Sales", code: TeamTypeEntity.SALES },
  { name: "Execution", code: TeamTypeEntity.EXECUTION },
];

export const allFeaturedModules = {
  offices: 'Offices',
  percentageUpdate: 'Percentage Update',
  statesCitiesMaster: 'States-Cities Master',
  pricingVersion: 'Pricing Version',
  dashboard: "Dashboard",
  wallOrders: "Waltz Orders",
  glassOrders: "Glass Orders",
  transactions: "Transactions",
  paymentHistory: "Payment History",
  settlements: "Settlements",
  draftTransactions: "Draft Transactions",
  refundPayment: "Refund Payment",
  refundPaymentHistory: "Refund Payment History",
  orderDue: "Order Due",
  panelOnly: "Panel Only",
  directory: "Directory",
  settings: "Settings",
  downloads: "Downloads",
  analytics: "Analytics",
  reports: "Reports",
  orderReport: "Order Report",
  generalSettings: "General Settings",
  states: "States",
  cities: "Cities",
  systems: "Systems",
  subSystems: "Sub System",
  systemTypes: "System Types",
  models: "Models",
  frameColors: "Frame Colors",
  handles: "Handles",
  locks: "Locks",
  designs: "Designs",
  grids: "Grids",
  users: "Users",
  hinges: "Hinges",
  kitchenHandlePositions: "Kitchen Handle Positions",
  kitchenHandle: "Kitchen Handle",
  glassOnlyFinish: "Glass Only Finishes",
  kitchenType: "Kitchen Type",
  waltzCategories: "Categories",
  waltzSubCategories: "Sub Categories",
  waltzFinishes: "Finishes",
  waltzVariants: "Variants",
  roleManagement: "Role Management",
  changePassword: "Change Password",
  PricingVersionsOrderWiseCounts: "Pricing Versions Order Wise Counts",
  executionOrder: "Execution Order",
  redFlag: "Red Flag",
  QuotationRequestUsers: "Get Quote Details",
  GlassCatalogues: "Glass Catalogues"
};

export const noDataAvailableInTable = "No data available in table.";

export const emptyMultiSelectSearchMessage = "No results found";
